import React from 'react'
import { Center, Text, Title } from '@mantine/core';
import styles from './Welcome.module.scss';
const Welcome = () => {
    return (
        <div className={styles.host}>
          <img src={process.env.PUBLIC_URL + '/alanguLogoSlogan.png'} width="70%" className={styles.logo}></img>
          <img src={process.env.PUBLIC_URL + '/poseILoveYou.png'} width="70%" className={styles.avatar}></img>
          <div className={styles.impressum}><a href={"/impressum"}>Impressum</a></div>
          <div className={styles.impText}><Title order={3}>Seite befindet sich im Aufbau!</Title></div>
        </div>
      );}
export default Welcome

