import logo from './logo.svg';
import './App.css';
import { BrowserRouter,  Navigate,  Route, Routes} from 'react-router-dom';
import  Welcome  from './Pages/Welcome/Welcome.tsx';
import Impressum from './Pages/Impressum/Impressum.tsx';



function App() {
 
  
  return (
    <div>
      <BrowserRouter>
   <Routes>
  
    <Route exact path='/' element={<Welcome/>} />
    <Route exact path='/impressum' element={<Impressum/>}/>   
    <Route path="*" element={<Navigate to="/"  replace/>} />
      </Routes></BrowserRouter>

   </div>
  );
}

export default App;
