import React from 'react'
import { Center, Divider, Text, Title } from '@mantine/core';
import styles from './Impressum.module.scss';

const Impressum = () => {
    return (
        <div className={styles.host}>
          <Center>
            <div className={styles.text}>
              <div className={styles.item}>
                <Title>Impressum</Title>
              </div>
              <Divider />
              <div className={styles.item}>
                <Title order={3}>alangu GmbH</Title>
              </div>
              <div className={styles.item}>Subbelrather Strasse 13 | 50672 Köln</div>
              <div className={styles.item}>Deutschland</div>
              <div className={styles.item}>
                <Title order={3}>Telefon:</Title>+49 (0) 2 21 - 33 66 4-0
              </div>
              <div className={styles.item}>
                <Title order={3}>Geschäftsführer:</Title>Ralph Raule, Alexander Stricker Verantwortlich
                für den Inhalt gem. § 10 Abs. 3 MDSt.V
              </div>
              <div className={styles.item}>
                <Title order={3}>Registergericht:</Title>Amtsgericht Köln
              </div>
              <div className={styles.item}>
                <Title order={3}>Registernummer:</Title>HRB 115428
              </div>
            </div>
          </Center>
          <div className={styles.link}>
            <a href={'/'}>Go Back</a>
          </div>
        </div>
      );
}

export default Impressum


